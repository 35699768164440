/* eslint-disable indent */
import days from "dayjs";
import ProgressBadge from "./ProgressBadge";
import SetStatus from "./SetStatus";
import { IBrief } from "../../../../interfaces/brief.interface";
import { determineBriefType } from "../../utils";
import "./BriefRow.scss";
import { useNavigate } from "react-router-dom";

const BriefRow = (props: {
  brief: IBrief;
  percentage: number;
  status: string;
  archiveBrief: Function;
  archiveTable: boolean;
  pid: number;
  briefs: IBrief[];
  setBriefs: (briefs: IBrief[]) => void;
}) => {
  let queryLink;
  const navigate = useNavigate();
  const type = determineBriefType(props.brief);
  const term = props.brief.h1;
  let step = "";
  if (type.includes("Legacy")) {
    const url = `/content-briefs/${props.brief.id}?pid=${props.pid}&phrase=${term}&type=${type}`;
    if (props.status === "complete") {
      const handleNavigate = () => {
        navigate(url);
      };
      queryLink = (
        <div>
          <a
            onClick={handleNavigate}
            id={`briefs-phrase-${term}`}
            style={{
              textDecoration: "underline",
              color: "#008CCE",
              cursor: "pointer"
            }}
          >
            {props.brief.h1}
          </a>
        </div>
      );
    } else {
      queryLink = props.brief.h1;
    }
  } else {
    const step_number = props.brief.current_step;
    if (step_number === 1) {
      step = "step-one";
    } else if (step_number === 2) {
      step = "step-two";
    } else if (step_number === 3 || step_number === 4) {
      step = "step-three";
    }
    let complete = "";
    if (step_number === 4) {
      complete = "&complete=true";
    }
    const url = `/content-briefs-workflow/${step}/${props.brief.id}?pid=${props.pid}${complete}&phrase=${term}&type=${type}`;
    const handleNavigate = () => {
      navigate(url);
    };
    if (props.status === "complete") {
      queryLink = (
        <div>
          <a
            onClick={handleNavigate}
            id={`briefs-phrase-${term}`}
            style={{
              textDecoration: "underline",
              color: "#008CCE",
              cursor: "pointer"
            }}
          >
            {props.brief.h1}
          </a>
        </div>
      );
    } else {
      queryLink = props.brief.h1;
    }
  }

  const maxWidth35 = {
    height: "20px",
    width: "35%"
  };
  const maxWidth20 = {
    height: "20px",
    width: "20%"
  };
  const maxWidth15 = {
    height: "20px",
    width: "15%"
  };

  return (
    <>
      {props.brief && (
        <tr
          className={`cbm-row cbm-row-id-${props.brief.id}`}
          id={`cbm-row-id-${props.brief.id}`}
        >
          <td className="query" style={maxWidth35}>
            {queryLink}
          </td>
          <td className="status" style={maxWidth15}>
            <SetStatus briefId={props.brief.id} status={props.brief.state} />
          </td>
          <td className="brief_type" style={maxWidth15}>
            <div className="d-flex align-items-center">{type}</div>
          </td>
          <td className="status" style={maxWidth15}>
            <div>
              <ProgressBadge
                status={props.brief.status}
                percentage={props.percentage}
                queryString={props.brief.h1}
              />
            </div>
          </td>
          <td className="date cbi-date" style={maxWidth20}>
            {days(props.brief.created_at).format("YYYY-MM-DD")}
          </td>
        </tr>
      )}
    </>
  );
};

export default BriefRow;
