// import { StyleSheet } from "@react-pdf/renderer";

// export const pdfBaseStyle = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "white"
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

export const stepThreeStyles = {
  paragraphText: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "141.7%"
  },
  titlePhotoTitleLine1: {
    color: "var(--gray-lightest, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  },
  titlePhotoTitleLine2: {
    color: "#373F50",
    fontFamily: "Source Sans Pro",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  },
  titlePhotoTitleLine3: {
    color: "var(--gray-light, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    paddingTop: "10px"
  },
  metaInfoLabel: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "160%"
  },
  metaInfoField: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%"
  },
  rowPaddingTop20: { paddingTop: "20px" },
  rowPaddingTop40: { paddingTop: "40px" },
  outlineTitle: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  outlineSubtitle: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  },
  outlineText: {
    color: "var(--secondary, #373F50)",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  wordWrap: {
    maxWidth: "450px",
    wordWrap: "break-word" as const
  }
};
